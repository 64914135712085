import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";

import moment from "moment";

import { withTranslation } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

import { load as loadInventory } from "../components/reducers/inventory";

import { connect } from "react-redux";

import { Field, reduxForm, FieldArray, arrayPush, formValueSelector } from "redux-form";

import fetchClient from "../components/utils/fetchClient";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 12
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 20
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing()
    },
    row: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
});

class renderProofUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImage: "",
            dialogOpen: false
        };

        this.showModal = this.showModal.bind(this);
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, dialogOpen: true });
        }
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div>
                <div>
                    {this.props.input.value
                        ? this.props.input.value.map((image, index) => (
                              <img
                                  src={image}
                                  height="50"
                                  width="50"
                                  key={index}
                                  style={{ paddingLeft: 2 }}
                                  className="uploaded-image"
                                  onClick={() => this.showModal(image)}
                                  alt={image.split(".com/").pop()}
                              />
                          ))
                        : null}
                </div>

                <br />
                {this.props.meta.error && <span className="error">{this.props.meta.error}</span>}

                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={() => {
                        this.setState({
                            dialogOpen: false
                        });
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        dialogOpen: false
                                    });
                                }}
                                color="inherit"
                                className={classes.menuButton}
                                aria-label={t("misc.Close")}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("new.ViewImage")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            paddingLeft: 15,
                            paddingTop: 75,
                            paddingRight: 15
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                            src={this.state.currentImage}
                            alt={this.state.currentImage.split("/").pop()}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <TextField
        error={error}
        InputProps={{
            inputProps: {
                style: { textAlign: "center", width: 40 }
            }
        }}
        {...input}
        {...custom}
    />
);

const renderTextArea = ({ input, label, meta: { touched, error }, ...custom }) => <TextField label={label} error={Boolean(error)} {...input} {...custom} />;

const renderInventories = ({ fields, meta: { touched, error, submitFailed }, classes, t }) => (
    <div>
        {fields.map((inventory, index) => (
            <div key={index}>
                <h2>
                    <i>{fields.get(index)["sectionName"]}</i>
                </h2>
                <FieldArray name={`${inventory}.products`} classes={classes} t={t} component={renderInventory} />
                <br />
                <Field index={index} component={renderProofUploader} t={t} classes={classes} name={`${inventory}.proofs`} />
            </div>
        ))}
    </div>
);

const renderInventory = ({ fields, meta: { error, submitFailed }, classes, t }) => (
    <div style={{ width: "auto", overflowX: "scroll" }}>
        <Table>
            <TableHead>
                <TableRow>
                    <CustomTableCell>{t("new.Name")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Amount")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Min")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Max")}</CustomTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map((product, index) => (
                    <TableRow className={classes.row} key={index}>
                        <CustomTableCell component="th" scope="row">
                            {fields.get(index)["productName"]}
                        </CustomTableCell>
                        <CustomTableCell align="center">
                            <Field style={{ margin: 2.5 }} component={renderTextField} disabled type="number" name={`${product}.lastAmount`} />
                        </CustomTableCell>
                        <CustomTableCell align="center">{fields.get(index)["minVal"]}</CustomTableCell>
                        <CustomTableCell align="center">{fields.get(index)["maxVal"]}</CustomTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        {error && <span>{error}</span>}
    </div>
);

class ViewInventory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inventory: [],
            isLoading: true,
            currentListId: this.props.match.params.id
        };
    }

    async componentDidMount() {
        let check = await this.fetchCheck();

        this.setState(
            {
                inventory: check,
                isLoading: false
            },
            () => {
                this.props.load(check);
            }
        );
    }

    async fetchCheck() {
        var self = this;

        return new Promise(function(resolve, reject) {
            fetchClient
                .get("/ia/checks/view/" + self.props.match.params.id)
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/list/" + this.state.inventory.listId);
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.ViewInventory", { param: this.state.inventory.listName }) : t("titles.Loading") + "..."}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <div>
                            <form>
                                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                                    {this.state.inventory.checkedByName && this.state.inventory.checkDate && (
                                        <div>
                                            {t("new.LastChecked")} {this.state.inventory.checkedByName} {t("new.CheckedAt")} {moment(this.state.inventory.checkDate).format("DD/MM/YYYY HH:mm")}
                                        </div>
                                    )}
                                </Typography>
                                <FieldArray name="inventories" classes={classes} component={renderInventories} t={t} />
                                <br />
                                <Field component={renderTextArea} type="text" name="checkNotes" disabled label={t("new.CheckNotes")} multiline fullWidth />
                            </form>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

ViewInventory = reduxForm({
    form: "inventoryform",
    destroyOnUnmount: true,
    enableReinitialize: true
})(ViewInventory);

const selector = formValueSelector("inventoryform");

ViewInventory = connect(
    (state, props) => ({
        initialValues: state.inventory.data,
        inventories: selector(state, "inventories")
    }),
    { load: loadInventory, arrayPush }
)(ViewInventory);

ViewInventory.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(ViewInventory)));
