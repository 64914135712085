const validate = (values, props) => {
    const errors = {};

    const { t } = props;

    if (!values.inventories || !values.inventories.length) {
        errors.inventories = {
            _error: t("misc.InventoryRequired")
        };
    } else {
        const inventoriesArrayErrors = [];
        values.inventories.forEach((inventory, inventoryIndex) => {
            const inventoryErrors = {};
            if (!inventory || !inventory.sectionName) {
                inventoryErrors.sectionName = t("misc.Required");
                inventoriesArrayErrors[inventoryIndex] = inventoryErrors;
            }
            if (!inventory || !inventory.proofs.length) {
                inventoryErrors.proofs = t("misc.ProofRequired");
                inventoriesArrayErrors[inventoryIndex] = inventoryErrors;
            }
        });
        if (inventoriesArrayErrors.length) {
            errors.inventories = inventoriesArrayErrors;
        }
    }
    return errors;
};

export default validate;
