import React from "react";
import { Redirect } from "react-router";

import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

class FindList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            listId: null
        };
    }

    async componentDidMount() {
        let list = await this.fetchList();

        this.setState({
            isLoading: false,
            listId: list.listId && list.listId > 0 ? list.listId : null
        });
    }

    async fetchList() {
        var self = this;
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("/ia/lists/find/" + self.props.match.params.id)
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        return !this.state.isLoading && (this.state.listId ? <Redirect to={"/list/" + this.state.listId} /> : <Redirect to={"/"} />);
    }
}

export default withAuth(FindList);
