import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";

import moment from "moment";
import store from "./store/store";

import { withTranslation } from "react-multi-lang";

import validate from "./validate";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import ImageCompressor from "uppy-plugin-image-compressor";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";

import withAuth from "../components/auth/withAuth";

import { load as loadInventory } from "../components/reducers/inventory";

import { connect } from "react-redux";

import { Field, reduxForm, FieldArray, arrayPush, change, formValueSelector } from "redux-form";

import fetchClient from "../components/utils/fetchClient";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 12
    }
}))(TableCell);

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 20
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingTop: theme.spacing()
    },
    row: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
});

class renderProofUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImage: "",
            dialogOpen: false
        };

        this.showModal = this.showModal.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.proofsuploader = new Uppy({
            id: "proofs",
            autoProceed: true,
            restrictions: {
                allowedFileTypes: ["image/*"]
            }
        })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "inventories",
                                upload_type: "inventory",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.props.change(`inventories.${this.props.index}.proofs`, [...this.props.input.value, resp.uploadURL]);

                this.props.saveState();
            });
    }

    componentWillUnmount() {
        this.proofsuploader.close();
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, dialogOpen: true });
        }
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div>
                <DragDrop
                    uppy={this.proofsuploader}
                    locale={{
                        strings: {
                            dropHereOr: t("new.Upload") + " %{browse}",
                            browse: t("new.Browse")
                        }
                    }}
                />
                <StatusBar uppy={this.proofsuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                <div>
                    {this.props.input.value
                        ? this.props.input.value.map((image, index) => (
                              <img
                                  src={image}
                                  height="50"
                                  width="50"
                                  key={index}
                                  style={{ paddingLeft: 2 }}
                                  className="uploaded-image"
                                  onClick={() => this.showModal(image)}
                                  alt={image.split(".com/").pop()}
                              />
                          ))
                        : null}
                </div>

                <br />
                {this.props.meta.error && <span className="error">{this.props.meta.error}</span>}

                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={() => {
                        this.setState({
                            dialogOpen: false
                        });
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        dialogOpen: false
                                    });
                                }}
                                color="inherit"
                                className={classes.menuButton}
                                aria-label={t("misc.Close")}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("new.ViewImage")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            paddingLeft: 15,
                            paddingTop: 75,
                            paddingRight: 15
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                            src={this.state.currentImage}
                            alt={this.state.currentImage.split("/").pop()}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const renderTextField = ({ input, label, meta: { touched, error }, incrementVal, decrementVal, parent, index, t, ...custom }) => (
    <TextField
        error={error}
        InputProps={{
            inputProps: {
                style: { textAlign: "center", width: 40 }
            },
            startAdornment: (
                <InputAdornment position="start">
                    <IconButton
                        onClick={() => {
                            incrementVal(input.value, parent, index);
                        }}
                        color="inherit"
                        aria-label={t("new.Plus")}
                    >
                        <Add style={{ fontSize: "0.6em" }} />
                    </IconButton>
                </InputAdornment>
            ),
            endAdornment: (
                <InputAdornment position="start">
                    <IconButton
                        onClick={() => {
                            decrementVal(input.value, parent, index);
                        }}
                        color="inherit"
                        aria-label={t("new.Minus")}
                    >
                        <Remove style={{ fontSize: "0.6em" }} />
                    </IconButton>
                </InputAdornment>
            )
        }}
        {...input}
        {...custom}
    />
);

const renderTextArea = ({ input, label, meta: { touched, error }, ...custom }) => <TextField label={label} error={Boolean(error)} {...input} {...custom} />;

const renderInventories = ({ fields, meta: { touched, error, submitFailed }, classes, incrementVal, decrementVal, change, saveState, t }) => (
    <div>
        {fields.map((inventory, index) => (
            <div key={index}>
                <h2>
                    <i>{fields.get(index)["sectionName"]}</i>
                </h2>
                <FieldArray name={`${inventory}.products`} incrementVal={incrementVal} parent={index} decrementVal={decrementVal} classes={classes} t={t} component={renderInventory} />
                <br />
                <Field change={change} index={index} saveState={saveState} component={renderProofUploader} t={t} classes={classes} name={`${inventory}.proofs`} />
            </div>
        ))}
    </div>
);

const renderInventory = ({ fields, meta: { error, submitFailed }, incrementVal, decrementVal, classes, parent, t }) => (
    <div style={{ width: "auto", overflowX: "scroll" }}>
        <Table>
            <TableHead>
                <TableRow>
                    <CustomTableCell>{t("new.Name")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Amount")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Min")}</CustomTableCell>
                    <CustomTableCell align="center">{t("new.Max")}</CustomTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map((product, index) => (
                    <TableRow className={classes.row} key={index}>
                        <CustomTableCell component="th" scope="row">
                            {fields.get(index)["productName"]}
                        </CustomTableCell>
                        <CustomTableCell align="center">
                            <Field
                                style={{ margin: 2.5 }}
                                parent={parent}
                                index={index}
                                t={t}
                                component={renderTextField}
                                decrementVal={decrementVal}
                                incrementVal={incrementVal}
                                type="number"
                                name={`${product}.lastAmount`}
                            />
                        </CustomTableCell>
                        <CustomTableCell align="center">{fields.get(index)["minVal"]}</CustomTableCell>
                        <CustomTableCell align="center">{fields.get(index)["maxVal"]}</CustomTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        {error && <span>{error}</span>}
    </div>
);

class NewInventory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inventory: [],
            isLoading: true,
            currentListId: this.props.match.params.id,
            isSubmitting: false
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.incrementVal = this.incrementVal.bind(this);
        this.decrementVal = this.decrementVal.bind(this);
        this.saveState = this.saveState.bind(this);
    }

    saveState() {
        let values = store.getState();

        if (values) {
            localStorage.setItem("ialastSave", JSON.stringify(values.form.inventoryform.values));
        }
    }

    async componentDidMount() {
        var lastSave = JSON.parse(localStorage.getItem("ialastSave"));

        if (lastSave && lastSave.listId === this.props.match.params.id) {
            this.setState({
                inventory: lastSave,
                isLoading: false
            });
            this.props.load(lastSave);
            localStorage.removeItem("ialastSave");
        } else {
            let inventory = await this.fetchInventory();

            this.setState(
                {
                    inventory: inventory,
                    isLoading: false
                },
                () => {
                    this.props.load(inventory);
                }
            );
        }
    }

    async fetchInventory() {
        var self = this;

        return new Promise(function(resolve, reject) {
            fetchClient
                .get("/ia/inventory/" + self.props.match.params.id)
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    saveChanges(values) {
        var self = this;

        self.setState({ isSubmitting: true });

        fetchClient
            .post("/ia/checks/new/" + self.state.inventory.listId, values)
            .then(function(response) {
                localStorage.removeItem("ialastSave");
                self.props.history.push("/list/" + self.state.inventory.listId);
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    incrementVal(val, parent, index) {
        this.props.change(`inventories[${parent}].products[${index}].lastAmount`, parseInt(val) + 1);

        this.saveState();
    }

    decrementVal(val, parent, index) {
        this.props.change(`inventories[${parent}].products[${index}].lastAmount`, parseInt(val) - 1);

        this.saveState();
    }

    render() {
        const { classes, handleSubmit, invalid, change, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/list/" + this.state.inventory.listId);
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.NewInventory", { param: this.state.inventory.listName }) : t("titles.Loading") + "..."}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <div>
                            <form onSubmit={handleSubmit(val => this.saveChanges(val))}>
                                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                                    {this.state.inventory.checkedByName && this.state.inventory.checkDate && (
                                        <div>
                                            {t("new.LastChecked")} {this.state.inventory.checkedByName} {t("new.CheckedAt")} {moment(this.state.inventory.checkDate).format("DD/MM/YYYY HH:mm")}
                                        </div>
                                    )}
                                </Typography>
                                <FieldArray
                                    name="inventories"
                                    incrementVal={this.incrementVal}
                                    decrementVal={this.decrementVal}
                                    classes={classes}
                                    change={change}
                                    saveState={this.saveState}
                                    component={renderInventories}
                                    t={t}
                                />
                                <br />
                                <Field component={renderTextArea} type="text" name="checkNotes" label={t("new.CheckNotes")} multiline fullWidth />
                                <br /> <br />
                                <Button variant="contained" color="primary" disabled={invalid || this.state.isSubmitting} type="submit" className={classes.button}>
                                    {t("new.Save")}
                                </Button>
                            </form>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

NewInventory = reduxForm({
    form: "inventoryform",
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
})(NewInventory);

const selector = formValueSelector("inventoryform");

NewInventory = connect(
    (state, props) => ({
        initialValues: state.inventory.data,
        inventories: selector(state, "inventories")
    }),
    { load: loadInventory, arrayPush, change }
)(NewInventory);

NewInventory.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(NewInventory)));
