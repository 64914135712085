import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Visibility from "@material-ui/icons/Visibility";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";

import AddIcon from "@material-ui/icons/Add";

import { withTranslation } from "react-multi-lang";

import moment from "moment";

import XLSX from "xlsx";

import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingTop: theme.spacing()
    },
    fab: {
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    }
});
class ListDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: {
                images: []
            },
            inventory: {
                checks: []
            },
            isLoading: true,
            currentListId: this.props.match.params.id,
            lastCheckId: null,
            exportData: [],
            currentImage: "",
            dialogOpen: false
        };

        this.showModal = this.showModal.bind(this);
        this.exportFile = this.exportFile.bind(this);
    }

    async componentDidMount() {
        let list = await this.fetchList();
        let inventory = await this.fetchChecks();

        this.setState({
            list: list,
            inventory: inventory,
            lastCheckId: inventory.lastCheckId,
            isLoading: false
        });
    }

    async fetchList() {
        var self = this;

        return new Promise(function(resolve, reject) {
            fetchClient
                .get("/ia/lists/" + self.props.match.params.id)
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    async fetchChecks() {
        var self = this;

        return new Promise(function(resolve, reject) {
            fetchClient
                .get("/ia/checks/" + self.props.match.params.id)
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, dialogOpen: true });
        }
    }

    exportFile() {
        var self = this;

        fetchClient
            .get("/ia/lists/export/" + self.state.currentListId)
            .then(function(response) {
                self.setState({ exportData: response.data.data }, () => {
                    const ws = XLSX.utils.aoa_to_sheet(self.state.exportData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                    XLSX.writeFile(wb, "inventoryAppExport" + moment().format("DDMMY") + ".xlsx");
                });
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading ? t("titles.ViewList", { param: this.state.list.listName }) : t("titles.Loading") + "..."}
                        </Typography>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                color="inherit"
                                style={!this.state.lastCheckId ? { display: "none" } : null}
                                onClick={() => {
                                    this.props.history.push("/check/view/" + this.state.lastCheckId);
                                }}
                            >
                                <Visibility />
                            </IconButton>

                            {!this.state.isLoading && (
                                <IconButton color="inherit" onClick={this.exportFile}>
                                    <CloudDownload />
                                </IconButton>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <div className={classes.content}>
                    {!this.state.isLoading && (
                        <div>
                            <div>
                                <Typography variant="h6" color="inherit" className={classes.grow}>
                                    {t("details.Information")}
                                </Typography>
                                <p>{this.state.list.listInfo}</p>
                                {this.state.list.images.map((inventory, index) => {
                                    return (
                                        <img className="uploaded-image" key={index} onClick={() => this.showModal(inventory)} src={inventory} height="50" width="50" alt={inventory.split("/").pop()} />
                                    );
                                })}
                                <Typography variant="h6" color="inherit" className={classes.grow}>
                                    {t("details.PreviousInventories")}
                                </Typography>
                                <List>
                                    {this.state.inventory.checks.map(inventory => {
                                        return (
                                            <ListItem
                                                key={inventory.checkId}
                                                button
                                                component="a"
                                                onClick={() => {
                                                    this.props.history.push("/check/view/" + inventory.checkId);
                                                }}
                                            >
                                                <ListItemText primary={moment(inventory.checkDate).format("DD/MM/YYYY HH:mm") + " " + inventory.checkedByName} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <Fab
                                    color="primary"
                                    aria-label={t("misc.Add")}
                                    className={classes.fab}
                                    onClick={() => {
                                        this.props.history.push("/check/new/" + this.state.currentListId);
                                    }}
                                >
                                    <AddIcon />
                                </Fab>

                                <div>
                                    <Dialog
                                        fullScreen
                                        open={this.state.dialogOpen}
                                        onClose={() => {
                                            this.setState({
                                                dialogOpen: false
                                            });
                                        }}
                                        TransitionComponent={Transition}
                                    >
                                        <AppBar className={classes.appBar}>
                                            <Toolbar>
                                                <IconButton
                                                    onClick={() => {
                                                        this.setState({
                                                            dialogOpen: false
                                                        });
                                                    }}
                                                    color="inherit"
                                                    className={classes.menuButton}
                                                    aria-label={t("misc.Close")}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                                    {t("details.ViewImage")}
                                                </Typography>
                                            </Toolbar>
                                        </AppBar>
                                        <div
                                            style={{
                                                paddingLeft: 15,
                                                paddingTop: 75,
                                                paddingRight: 15
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: "100%",
                                                    height: "auto"
                                                }}
                                                src={this.state.currentImage}
                                                alt={this.state.currentImage.split("/").pop()}
                                            />
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

ListDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(ListDetails)));
