import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AllInbox from "@material-ui/icons/AllInbox";

import { withTranslation } from "react-multi-lang";

import AuthService from "../components/auth/AuthService";
import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const Auth = new AuthService();

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    headerIcon: {
        marginRight: 5,
        marginLeft: 5,
        [theme.breakpoints.down("md")]: {
            marginRight: 12,
            marginLeft: 12,
            marginTop: 12,
            marginBottom: 12
        }
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(9),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    searchInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(8),
        transition: theme.transitions.create("width"),
        width: "100%"
    }
});

class InventoriesList extends React.Component {
    constructor() {
        super();

        this.state = {
            drawer_open: false,
            inventoryLists: [],
            initialinventoryLists: [],
            filters: {
                viewSearch: ""
            },
            isLoading: true
        };

        var decoded = Auth.getProfile();
        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userName = decoded.userName;

        this.logout = this.logout.bind(this);
        this.handleListSearch = this.handleListSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);

        var iaViewConfig = JSON.parse(localStorage.getItem("iaViewConfig"));
        if (iaViewConfig) {
            Object.keys(iaViewConfig).forEach(key => {
                this.state.filters[key] = iaViewConfig[key];
            });
        }
    }

    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleListSearch = event => {
        var updatedList = this.state.initialinventoryLists;
        updatedList = updatedList.filter(function (item) {
            return item.listName.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });

        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewSearch: event.target.value
                },
                inventoryLists: updatedList
            },
            () => {
                localStorage.setItem("iaViewConfig", JSON.stringify(this.state.filters));
            }
        );
    };

    clearSearch() {
        this.setState(
            {
                filters: {
                    ...this.state.filters,
                    viewSearch: ""
                },
                inventoryLists: this.state.initialinventoryLists
            },
            () => {
                localStorage.setItem("iaViewConfig", JSON.stringify(this.state.filters));
            }
        );
    }

    async componentDidMount() {
        var self = this;
        let lists = await self.fetchLists();

        var updatedList = lists;

        if (self.state.filters.viewSearch.length > 0) {
            updatedList = updatedList.filter(function (item) {
                return item.listName.toLowerCase().search(self.state.filters.viewSearch.toLowerCase()) !== -1;
            });
        }

        self.setState({
            initialinventoryLists: lists,
            inventoryLists: updatedList,
            isLoading: false
        });
    }

    async fetchLists() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("ia/lists")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { anchorEl } = this.state;
        const { classes, t } = this.props;
        const isMenuOpen = Boolean(anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem
                    onClick={() => {
                        this.props.history.push("/settings/");
                    }}
                >
                    <p>{t("nav.Settings")}</p>
                </MenuItem>

                <MenuItem onClick={this.logout}>
                    <p>{t("nav.Logout", { param: this.state.userName })}</p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <AllInbox className={classes.headerIcon} />
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            inventoryApp
                        </Typography>
                        <div className={classes.grow}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    value={this.state.filters.viewSearch}
                                    onChange={this.handleListSearch}
                                    placeholder={t("list.Search") + "..."}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.searchInput
                                    }}
                                    endAdornment={
                                        this.state.filters.viewSearch.length > 0 && (
                                            <InputAdornment position="end">
                                                <IconButton aria-label={t("list.Clear")} onClick={this.clearSearch}>
                                                    <ClearIcon style={{ color: "white" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.handleMenuOpen} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {renderMenu}

                {this.state.isLoading && <LinearProgress color="secondary" />}

                <List>
                    {this.state.inventoryLists.map(list => {
                        return (
                            <div key={list.listId}>
                                <ListItem
                                    button
                                    component="a"
                                    onClick={() => {
                                        this.props.history.push("/list/" + list.listId + "");
                                    }}
                                >
                                    <ListItemText primary={list.listName} />
                                </ListItem>
                                <Divider className={classes.divider} />
                            </div>
                        );
                    })}
                </List>
            </div>
        );
    }
}

InventoriesList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withAuth(withStyles(styles)(InventoriesList)));
