import { combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import inventory from "../reducers/inventory";

const rootReducer = combineReducers({
    inventory,
    form: formReducer
});

const store = createStore(rootReducer);

export default store;
