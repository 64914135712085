import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./components/store/store";
import { Provider } from "react-redux";

import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

setDefaultTranslations({ es, en });
setDefaultLanguage("en");

var iaSettings = JSON.parse(localStorage.getItem("iaSettings"));
if (iaSettings) {
    if (iaSettings.language) {
        setLanguage(iaSettings.language);
    }
} else {
    localStorage.setItem("iaSettings", JSON.stringify({ language: "en" }));
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
